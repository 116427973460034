.rs-sidebar-wrapper {
    overflow-x: hidden;
}
.rs-sidebar-wrapper.fixed {
    position: fixed;
    background-color: #f4f5f7;
    width: 260px;
    left: 0px;
    top: 0;
    bottom: 0;
    z-index: 5;
}
@media (max-width: 748px) {
    .rs-sidebar-wrapper.fixed {
        visibility: hidden;
        pointer-events: none;
   }
    .rs-sidebar-wrapper.fixed.media-sidebar-show {
        visibility: visible;
        pointer-events: all;
        animation: slide-in-left 0.3s ease-in-out;
        left: 0;
        z-index: 999;
   }
}
.rs-sidebar-wrapper.fixed .media-close-side-bar-button {
    display: none;
}
@media (max-width: 748px) {
    .rs-sidebar-wrapper.fixed .media-close-side-bar-button {
        display: block;
        position: absolute;
        right: 10px;
        top: 16px;
   }
}
.rs-sidebar-wrapper.fixed .title-wrapper {
    padding: 20px 20px 10px 20px;
    font-size: 20px;
}
.rs-sidebar-wrapper.fixed .title-wrapper i {
    font-size: 20px;
}
.rs-sidebar-wrapper.fixed .title-wrapper svg {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 4px;
    fill: currentColor;
}
.rs-theme-dark .rs-sidebar-wrapper.fixed, .rs-theme-high-contrast .rs-sidebar-wrapper.fixed {
    background-color: #1a1d24;
}
@media (max-width: 748px) {
    .rs-sidebar-media-backdrop {
        background-color: var(--rs-bg-backdrop);
        position: fixed;
        width: 100vw;
        height: 100vh;
        display: none;
        z-index: 1;
   }
    .rs-sidebar-media-backdrop.media-sidebar-show {
        display: block;
   }
}
.media-toggle-side-bar.rs-btn.rs-btn-icon {
    position: fixed;
    top: 10px;
    z-index: 1;
    box-shadow: 1;
}
@media (min-width: 748px) {
    .media-toggle-side-bar.rs-btn.rs-btn-icon {
        display: none;
   }
}
.rs-nav.nav-docs {
    overflow: auto;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    top: 80px;
    padding: 0 10px;
}
.rs-nav.nav-docs .rs-nav-item {
    font-weight: 400;
    color: inherit;
    transition: none;
    border-radius: 2px;
    padding-left: 20px;
}
.rs-nav.nav-docs .rs-nav-item-active {
    color: var(--rs-navs-selected);
    font-weight: bold;
}
.rs-nav.nav-docs .title-zh {
    font-size: 12px;
    margin-left: 4px;
    opacity: 0.5;
}
.rs-nav.nav-docs .rs-nav-item-panel {
    padding: 20px 12px 12px 12px;
    color: #aaa;
    border-bottom: 1px solid var(--rs-border-primary);
}
/* .rs-nav.nav-docs::-webkit-scrollbar {
    border-radius: / 2;
    width: ;
}
.rs-nav.nav-docs::-webkit-scrollbar-thumb {
    border-radius: / 2;
    background-color: rgba(141,141,141,0.3);
}
.rs-nav.nav-docs::-webkit-scrollbar-thumb:hover {
    background-color: rgba(141,141,141,0.9);
}
.rs-nav.nav-docs::-webkit-scrollbar-track {
    border-radius: / 2;
    background: transparent;
} */
.submenu-close .rs-nav.nav-docs {
    overflow: hidden;
}


.page-toolbar {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 6;
}
.page-toolbar .rs-btn-subtle .rs-icon {
    fill: currentColor;
}
@media (max-width: 748px) {
    .page-toolbar {
        position: static;
        margin-top: 60px;
        text-align: right;
   }
}
.more-actions-panel {
    padding: 8px 12px;
}
.more-actions-panel .rs-icon {
    width: 14px;
    text-align: center;
    margin-right: 6px;
}
.more-actions-panel .theme-item, .more-actions-panel .rtl-item {
    display: flex;
    align-items: center;
}
.more-actions-panel .theme-item .item-name, .more-actions-panel .rtl-item .item-name {
    cursor: pointer;
    flex: auto;
}
.theme-panel {
    padding: 8px 0 8px 12px;
}
